import { WorkmapsContext } from "@/components/WorkmapsFiltersProvider"
import type { WorkmapsState } from "@/types"

import { usePathname, useSearchParams } from "next/navigation"
import { useCallback, useContext, useState } from "react"
import { useDebouncedEffect } from "@react-hookz/web"

export type UseCurrentUrlParams = {
  includeSearch?: boolean
}

export const useCurrentUrl = ({ includeSearch = true }: UseCurrentUrlParams = { includeSearch: true }) => {
  const path = usePathname()!
  const searchParams = useSearchParams()!
  const url = new URL(path, process.env.NEXT_PUBLIC_SITE_URL)

  if (includeSearch) url.search = searchParams.toString()

  return url.toString()
}

export const useWorkmapsContext = () => {
  return useContext(WorkmapsContext) as WorkmapsState
}

export function useDebugFeedColors() {
  return useGate("debug_feed_colors")
}

export const useDebouncedValue = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useDebouncedEffect(() => setDebouncedValue(value), [value], delay)

  return debouncedValue
}

// Copied in from @mantine/hooks
// https://github.com/mantinedev/mantine/blob/2f91229da10d30a205ade277161bc8829740bd93/packages/%40mantine/hooks/src/use-disclosure/use-disclosure.ts
export function useDisclosure(initialState = false, callbacks?: { onOpen?: () => void; onClose?: () => void }) {
  const { onOpen, onClose } = callbacks || {}
  const [opened, setOpened] = useState(initialState)

  const open = useCallback(() => {
    setOpened((isOpened) => {
      if (!isOpened) {
        onOpen?.()
        return true
      }
      return isOpened
    })
  }, [onOpen])

  const close = useCallback(() => {
    setOpened((isOpened) => {
      if (isOpened) {
        onClose?.()
        return false
      }
      return isOpened
    })
  }, [onClose])

  const toggle = useCallback(() => {
    opened ? close() : open()
  }, [close, open, opened])

  return [opened, { open, close, toggle }] as const
}

// https://stackoverflow.com/a/68509263/471292
import { MutableRefObject, useRef } from "react"
import { useGate } from "./statsig"

type UseElementsRefResult<T extends HTMLElement = HTMLElement> = [
  /**
   * Map of DOM node refs identified by an auto-created key or custom one.
   */
  MutableRefObject<{ readonly [K: string | number]: T }>,

  /**
   * Creates a memoized callback ref for a DOM node.
   * If you expect the rendering order of nodes to change, use a custom key.
   *
   * @param customKey Key used to set/unset the node ref.
   */
  (customKey?: string | number) => (el?: T) => void,
]

/**
 * Hook that creates callback refs to manage DOM node refs of multiple elements.
 * Ensures refs remain stable even with re-renders. Use custom keys if the rendering order may change.
 */
export const useElementsRef = <T extends HTMLElement = HTMLElement>(): UseElementsRefResult<T> => {
  const elementRefs = useRef({
    current: {} as { [K: string | number]: T },
    callbacks: {} as Record<string | number, (el?: T) => void>,
  })

  // Resets index on every render; stable refs as long as the render order remains unchanged
  let currentIndex = 0

  return [
    elementRefs.current,
    (customKey) => {
      const { current: elements, callbacks } = elementRefs.current
      const autoKey = currentIndex++
      const key = customKey ?? autoKey

      // Memoize callback for stable ref assignment
      if (!callbacks[key]) {
        callbacks[key] = (el) => {
          // React is setting the ref for this node
          if (el != null) {
            elements[key] = el
            return
          }

          // React is clearing the node ref
          delete elements[key]
        }
      }

      return callbacks[key]
    },
  ]
}
