import type { Job, ListJob, StoreSearchResult } from "@/database-types"
import type { ApiStoreSearchResult, ApiListJob, Coordinates } from "@/types"
import type { Feature } from "geojson"
import { promotedEmployers } from "./shared/data"

export const decimalToNumber = (value: number | string | null | undefined): number => {
  return typeof value === "number" ? value : parseFloat(value ?? "0")
}

export const getHighestPay = (jobs: (Job | ListJob | ApiListJob)[]): number => {
  return jobs.reduce(
    (highestPay: number, job) =>
      decimalToNumber(job.pay_max) > highestPay ? decimalToNumber(job.pay_max) : highestPay,
    0
  )
}

export const getLowestPay = (jobs: (Job | ListJob | ApiListJob)[]): number => {
  return jobs.reduce(
    (lowestPay: number, job) => (decimalToNumber(job.pay_min) > lowestPay ? decimalToNumber(job.pay_min) : lowestPay),
    0
  )
}

export const getMeanPay = (jobs: (Job | ListJob | ApiListJob)[]): number => {
  const jobsWithPay = jobs.filter((job) => decimalToNumber(job.pay_max) > 0)
  if (jobsWithPay.length === 0) return 0
  const totalPay = jobsWithPay.reduce((totalPay: number, job) => totalPay + decimalToNumber(job.pay_max), 0)
  return Math.round((totalPay / jobsWithPay.length) * 100) / 100
}

export const formatMoney = (amount: number): string => {
  return amount % 1 === 0 ? `$${amount}` : `$${amount.toFixed(2)}`
}

export const payRangeTextContent = (min: number, max: number) => {
  if (min === max || (min && !max)) return `${formatMoney(min)} per hour`
  return `${formatMoney(min)} - ${formatMoney(max)} per hour`
}

export const payRangeTextContentSmall = (_min: number, max: number) => {
  return "$" + max + "/hr"
}

export const fetchImageAsBase64 = async (url: string): Promise<string> => {
  const response = await fetch(url)
  const blob = await response.blob()

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = function() {
      const base64data = reader.result?.toString() || ""
      resolve(base64data)
    }
    reader.onerror = function(error) {
      reject(error)
    }
    reader.readAsDataURL(blob)
  })
}

export const mapStoreToGeoJsonFeature = (store: StoreSearchResult | ApiStoreSearchResult) => {
  const feature: Feature = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [(store as any).lon as number, (store as any).lat as number],
    },
    properties: {
      store: store.id,
      pay: store.jobs?.[0]?.pay_max,
    },
  }
  return feature
}

export const isStorePromoted = (store: StoreSearchResult | ApiStoreSearchResult): boolean => {
  if (store.employer?.title && promotedEmployers.includes(store.employer.title)) {
    return true
  }

  return false
}

export const searchResultsHaveDoorDash = ({ }: StoreSearchResult | ApiStoreSearchResult): boolean => {
  if (promotedEmployers.includes("doordash")) {
    return true
  }

  return false
}

export const linkWithParams = (link: string, campaign: "sidebar" | "interstitial" | "map_pin") => {
  const url = new URL(link)
  url.searchParams.set("utm_source", "workmaps")
  url.searchParams.set("utm_campaign", campaign)
  return url.toString()
}

export const links = {
  hopskipdrive: {
    apply: "https://lfx2.app.link/0VssfRdvICb?%243p=a_custom_1213597501014805857",
    terms: "https://help.hopskipdrive.com/en_us/the-hello-and-welcome-promotion-ryKgIGg2",
  },
  urbansitter: {
    apply: "https://www.urbansitter.com/signup/sitter",
  },
}

type FetchWithTimeoutOptions = RequestInit & {
  timeout?: number
}

export const fetchWithTimeout = async (input: RequestInfo | URL, init: FetchWithTimeoutOptions = {}) => {
  const { timeout = 10_000 } = init

  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)

  const response = await fetch(input, {
    ...init,
    signal: controller.signal,
  })

  clearTimeout(id)

  return response
}

export const formatOutboundJobUrl = (jobId: string): URL => {
  return new URL(`/job-applications/outbound/${jobId}`, process.env.NEXT_PUBLIC_SITE_URL)
}

interface GetLatLonBoxFromState {
  lat: number
  lng: number
  zoom?: number
  map?: google.maps.Map
  /**
   * Defines what method to draw the box around the map's bounds. The default
   * is currently `static`.
   *
   * `static` will ±0.2 lat and ±0.4 lng
   *
   * `viewport` will use the `map`'s bounds to as the box to search for jobs
   * within. If the `map` is not set, it will default to the default `static`
   * offset.
   *
   * `dynamic-static` will use different static offsets based upon zoom level. As
   * the user zooms in, the list will narrow and expand as they zoom out. At
   * a zoom level of 16 and 17, the offset will be applied to the map's bounding
   * box. If a `minPay` is defined, the box is expanded slightly such that
   * results are more likely to be found.
   *
   * `dyanmic-static-v2` is the same as `dynamic-static` but with smaller boxes that
   * are informed by metrics collected by user's actual map sizes.
   */
  mapBounds: "viewport" | "static" | "dynamic-static" | "dynamic-static-v2" | "pins"
  /**
   * When the `mapBounds` is `dynamic-static`, this will increase the box's
   * size to account for the user showing intext on pay being more important than
   * distance.
   */
  payMin?: number | null
  isMobile?: boolean
  mapVisible?: boolean
}

const defaultLatOffset = 0.2
const defaultLngOffset = 0.4

/**
 * Given selected elements of the MapState, calculate the lat/lng box to search
 * for jobs within.
 */
export function getLatLonBoxFromState({
  lat,
  lng,
  zoom,
  mapBounds,
  map,
  payMin,
  isMobile,
  mapVisible,
}: GetLatLonBoxFromState) {
  const bounds = map?.getBounds?.()
  const staticBounds = {
    lat0: lat - defaultLatOffset,
    lat1: lat + defaultLatOffset,
    lon0: lng - defaultLngOffset,
    lon1: lng + defaultLngOffset,
  }

  // Mobile list view should get the maximal viewport
  if (isMobile && !mapVisible) {
    return staticBounds
  }

  // Mobile map view should only show what is visible
  if (isMobile && mapVisible && bounds) {
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    return {
      lat0: sw.lat(),
      lat1: ne.lat(),
      lon0: sw.lng(),
      lon1: ne.lng(),
    }
  }

  if (mapBounds === "static" || ((mapBounds === "viewport" || mapBounds === "pins") && !bounds)) {
    return staticBounds
  }

  if (mapBounds === "viewport" && bounds) {
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    return {
      lat0: sw.lat(),
      lat1: ne.lat(),
      lon0: sw.lng(),
      lon1: ne.lng(),
    }
  }

  if (mapBounds === "pins" && bounds) {
    const ne = bounds.getNorthEast()
    const sw = bounds.getSouthWest()
    return {
      lat0: sw.lat(),
      lat1: ne.lat(),
      lon0: sw.lng(),
      lon1: ne.lng(),
    }
  }

  let latOffset = defaultLatOffset
  let lngOffset = defaultLngOffset

  // The distances below are based upon NYC and could be skewed by screen size
  if (mapBounds === "dynamic-static") {
    switch (zoom) {
      // 2.82 miles
      case 17:
        latOffset = 0.01
        lngOffset = 0.015

        if (payMin) {
          latOffset += 0.005
          lngOffset += 0.01
        }

        if (bounds) {
          const ne = bounds.getNorthEast()
          const sw = bounds.getSouthWest()
          return {
            lat0: sw.lat() - latOffset,
            lat1: ne.lat() + latOffset,
            lon0: sw.lng() - lngOffset,
            lon1: ne.lng() + lngOffset,
          }
        }

      // 3.96 miles
      case 16:
        latOffset = 0.01
        lngOffset = 0.02

        if (payMin) {
          latOffset += 0.0075
          lngOffset += 0.01
        }

        if (bounds) {
          const ne = bounds.getNorthEast()
          const sw = bounds.getSouthWest()
          return {
            lat0: sw.lat() - latOffset,
            lat1: ne.lat() + latOffset,
            lon0: sw.lng() - lngOffset,
            lon1: ne.lng() + lngOffset,
          }
        }
        break

      // 6.27 miles
      case 15:
        latOffset = 0.025
        lngOffset = 0.05

        if (payMin) {
          latOffset += 0.01
          lngOffset += 0.02
        }
        break

      // 12.54 miles
      case 14:
        latOffset = 0.05
        lngOffset = 0.1

        if (payMin) {
          latOffset += 0.02
          lngOffset += 0.04
        }
        break

      // 25.08 miles
      case 13:
        latOffset = 0.1
        lngOffset = 0.2
        break

      // 50.17 miles
      case 11:
      case 12:
      default:
        latOffset = 0.2
        lngOffset = 0.4
    }
  } else if (mapBounds === "dynamic-static-v2") {
    // To make this easier to understand than the original dynamic-static algorithm, I am going to specify the
    // hypotenuse length of the map in miles and calculate the lat/lng offsets from that.
    // https://mixpanel.com/s/2WxVGn
    const latOffsetRatio = 0.2
    const lngOffsetRatio = 0.4
    const milesPerDegreeLat = 69.0 // roughly true globally
    const milesPerDegreeLng = 69.0 * Math.cos((lat * Math.PI) / 180) // adjusted for latitude
    const zoomToMiles: Record<number, number> = {
      11: 40,
      12: 20,
      13: 10,
      14: 5.7,
      15: 3,
      16: 1.4,
      17: 0.7,
    }
    const z = zoom && zoomToMiles[zoom] ? zoomToMiles[zoom] : zoomToMiles[12]
    const k = z / Math.sqrt((latOffsetRatio * milesPerDegreeLat) ** 2 + (lngOffsetRatio * milesPerDegreeLng) ** 2)
    latOffset = latOffsetRatio * k
    lngOffset = lngOffsetRatio * k
  }

  return {
    lat0: lat - latOffset,
    lat1: lat + latOffset,
    lon0: lng - lngOffset,
    lon1: lng + lngOffset,
  }
}

export const isRecentlyAdded = (postedAt: string | null | undefined) => {
  if (postedAt === undefined || postedAt === null) {
    return false
  }
  const threeDaysAgo = new Date()
  threeDaysAgo.setDate(threeDaysAgo.getDate() - 3)
  const threeDaysAgoString = threeDaysAgo.toISOString().split("T")[0]
  return postedAt >= threeDaysAgoString
}

export const uuidToNumberLossy = (uuid: string): number => {
  // Remove hyphens from the uuid
  const hexStr = uuid.replace(/-/g, "")

  // Divide the string into smaller parts to avoid number overflow
  const part1 = parseInt(hexStr.slice(0, 8), 16) // First 8 hex digits
  const part2 = parseInt(hexStr.slice(8, 16), 16) // Next 8 hex digits
  const part3 = parseInt(hexStr.slice(16, 24), 16) // Next 8 hex digits
  const part4 = parseInt(hexStr.slice(24, 32), 16) // Next 8 hex digits

  // Combine parts into a single number, normalizing each to ensure we don't exceed the floating-point precision
  const combined =
    part1 * Math.pow(2, -32) + part2 * Math.pow(2, -64) + part3 * Math.pow(2, -96) + part4 * Math.pow(2, -128)

  // The value will already be between 0 and 1, no further normalization required
  return combined
}

type Replacements = {
  [key: string]: string
}

export function replaceTokens(source: string, replacements: Replacements): string {
  return source.replace(/\{(\w+)\}/g, (match, key) => {
    return replacements.hasOwnProperty(key) ? replacements[key] : match
  })
}

export type SelectedGeoSlugData = {
  state?: string
  region?: string
  city?: string
  utmTerm?: string
}

export function selectSlugKeys<T extends object, K extends keyof T>(obj: T, keys: K[]): SelectedGeoSlugData {
  return Object.fromEntries(keys.filter((key) => key in obj).map((key) => [key, obj[key]])) as Pick<T, K>
}

export function getDistanceBetweenTwoPoints(cord1: Coordinates, cord2: Coordinates, unit: "km" | "miles" = "miles") {
  if (cord1.lat == cord2.lat && cord1.lon == cord2.lon) {
    return 0
  }

  const radlat1 = (Math.PI * cord1.lat) / 180
  const radlat2 = (Math.PI * cord2.lat) / 180

  const theta = cord1.lon - cord2.lon
  const radtheta = (Math.PI * theta) / 180

  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)

  if (dist > 1) {
    dist = 1
  }

  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515

  if (unit == "km") {
    return dist * 1.609344 //convert miles to km
  }

  return dist
}
