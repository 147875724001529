import { type EvaluationOptionsCommon, useStatsigClient } from "@statsig/react-bindings"
import { useMemo } from "react"
import { SidebarFilterType } from "@/types"
import { useIsMedium } from "../util"
import type { LayerType } from "./useMapLayers"
import type { JobPageProps } from "@/components/job-page-v3/JobPage"

// We export these hooks because previous versions of the Statsig SDK did not have centralized logging capabilities and
// required wrapper functions that this codebase has come to depend on. We are going to require imports from this
// module instead of the default for now.
export {
  useStatsigClient,
  useExperiment,
  useGateValue as useGate,
  useLayer,
  useDynamicConfig,
} from "@statsig/react-bindings"

// Layer specific hooks!
export function useStoreFeedLayer() {
  // We are using the client directly to follow Statsig recommendations. Technically, exposures for a layer experiment
  // only happens on calling `.get`, unlike a `useExperiment`, which is triggered upon calling it, but we just want to
  // be consistent with the rest of the codebase.
  const client = useStatsigClient()
  const isMedium = useIsMedium()

  return useMemo(
    () =>
      ({
        emailCaptureV2: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("email_capture_v2", false)
        },
        emailCaptureV2GoogleLoginEnabled: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("email_capture_v2_google_login_enabled", false)
        },
        emailCaptureDismissals: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return Number(layer.get("email_capture_dismissals") ?? "0")
        },
        jobPageModal: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("job_page_modal", false)
        },
        jobPageSectionOrder: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("job_page_section_order", ["header", "map", "perks", "description"]) as NonNullable<
            JobPageProps["sectionOrder"]
          >
        },
        smsCaptureModal: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("sms_capture_modal", false)
        },
        refineYourLocation: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("refine_your_location", false)
        },
        refineYourLocationModalOpened: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("refine_your_location_modal_opened", false)
        },
        exactLocationOnly: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("exact_location_only", false)
        },
        mapPinModal: (options?: EvaluationOptionsCommon) => {
          if (isMedium) return false
          const layer = client.getLayer("store_feed", options)
          return layer.get("map_pin_modal", false)
        },
        defaultSidebarFilters: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("default_sidebar_filters", [
            "Minimum Pay",
            "Shift Preference",
            "Benefits Offered",
            "Age Requirement",
            "Additional Requirement",
          ]) as SidebarFilterType[]
        },
        defaultSidebarFiltersV2: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("default_sidebar_filters_v2", [
            "Industry",
            "Minimum Pay",
            "Shift Preference",
            "Benefits Offered",
            "Age Requirement",
            "Additional Requirement",
          ]) as SidebarFilterType[]
        },
        sidebarFilterVersion: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("sidebar_filter_version", "v1")
        },
        slugPagesWithFiltersDisabled: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("slug_pages_with_filters_disabled", [
            "rn-jobs-near-me",
            "radiologic-technician-jobs-near-me",
            "physical-therapist-jobs-near-me",
            "dental-hygienist-jobs-near-me",
            "vocational-nurse-jobs-near-me",
            "dental-jobs-near-me",
            "healthcare-jobs-near-me",
            "nurse-jobs-near-me",
          ])
        },
        alertWidget: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("alert_widget", false)
        },
        alertWidgetNoResults: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("alert_widget_no_results", false)
        },
        alertWidgetMobileSearch: (options?: EvaluationOptionsCommon) => {
          const layer = client.getLayer("store_feed", options)
          return layer.get("alert_widget_mobile_search", false)
        },
      }) satisfies Record<string, (options?: EvaluationOptionsCommon) => any>,
    [client, isMedium]
  )
}

export function useStoreCardLayer() {
  // const client = useStatsigClient()

  return useMemo(() => ({}) satisfies Record<string, () => any>, [])
}

export function useMapLayer() {
  const client = useStatsigClient()
  const isMedium = useIsMedium()

  return useMemo(
    () => ({
      googleMapsMapId: () => {
        const layer = client.getLayer("map")
        return layer.get("google_maps_map_id", "58b3fe2bea24ce17")
      },
      mapPinVariations: () => {
        if (isMedium) return "default"
        const layer = client.getLayer("map")
        return layer.get<"default" | "logo" | "logo_pay" | "pay">("map_pin_variations", "default")
      },
      layerControlsEnabled: () => {
        const layer = client.getLayer("map")
        return layer.get("layer_controls_enabled", false)
      },
      defaultLayersEnabled: () => {
        const layer = client.getLayer("map")
        return layer.get<LayerType[]>("default_layers_enabled", []) as LayerType[]
      },
      pinPerkHighlights: () => {
        if (isMedium) return false
        const layer = client.getLayer("map")
        return layer.get("pin_perk_highlights", false)
      },
      pinHighlights: () => {
        if (isMedium) return false
        const layer = client.getLayer("map")
        return layer.get("pin_highlights", false)
      },
    }),
    [client, isMedium]
  )
}
