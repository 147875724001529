"use client"

import type { InitialLocationGeoData, MapState, SsrExposedExperiment, WorkmapsState } from "@/types"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { createContext, useEffect, useMemo } from "react"
import { IJobsCatalogContent } from "./WorkmapsJobsCatalogProvider"
import { useSearchParams } from "next/navigation"
import { getSearchParamsForMapState } from "@/lib/shared/util"
import { useMap } from "@/components/MapProvider"
import { useIsMedium, replaceURL } from "@/lib/frontend/util"
import { useCurrentUrl } from "@/lib/frontend/hooks"
import { useStatsigClient } from "@statsig/react-bindings"
import { useImmerReducer } from "use-immer"
import "@/lib/qa/wdyr"

export type WorkmapsFiltersProps = React.PropsWithChildren<
  Omit<MapState, "stores" | "storesPromise" | "driftedFromDefaultState" | "onNextStoresUpdate">
> & {
  initialJobsCount?: number
  ssrExposedExperiments?: SsrExposedExperiment[]
  userGeo: InitialLocationGeoData
}

export const WorkmapsContext = createContext<WorkmapsState | undefined>(undefined)

/**
 * This is a provider that provides functions to update the job search filters for the application. Updating the filters
 * may also update the URL so that the filtered results can be linked to.
 */
export const WorkmapsFiltersProvider: React.FC<WorkmapsFiltersProps> = ({
  children,
  initialJobsCount,
  ssrExposedExperiments,
  ...initialFilters
}) => {
  const queryClient = useQueryClient()

  const initialState: MapState = {
    ...initialFilters,
    lat: initialFilters.lat,
    lng: initialFilters.lng,
    driftedFromDefaultState: !initialJobsCount || initialJobsCount === 0,
    payMin: initialFilters.payMin ?? initialFilters.softMinPay,
    callerLocation: "initialState",
  }

  // Using immer allows us to make updates in a fashion that will prevent unnecessary renders.
  const [state, dispatch] = useImmerReducer<MapState, Partial<MapState>>(
    (draft, { callerLocation = "unknown", ...update }) => {
      // useReducer runs the risk of running multiple times with the initial state. We should bail out of these updates
      // to save a render cycle
      if (callerLocation === "initialState") {
        return
      }

      // It is sometimes helpful to see when this reducer runs to debug unneeded renders.
      /* eslint-disable-next-line no-console */
      console.debug(`debug.filtersUpdate.${callerLocation}`, { update })

      const driftedFromDefaultState = draft.driftedFromDefaultState

      // Apply the update like so to allow arrays and other non-primitive values to retain their identity
      for (const key in update) {
        // @ts-expect-error We need to update the draft incrementally so we do not throw off the identity of objects
        draft[key] = update[key]
      }

      // If the user landed on the site with a store ID and moves the map/filters, unselect it so the sidebar does not
      // scroll to it.
      if (draft.storeId === initialFilters.storeId) {
        draft.storeId = undefined
      }

      // We only want to refresh the SSR map if the user moves it or applies a filter, not if they click on a job/store,
      // as that will refresh the list and possibly remove the target from the list.
      if (!driftedFromDefaultState && !("job" in update) && !("store" in update)) {
        draft.driftedFromDefaultState = true
      }

      // If we trigger a job click, we need to set the storeId to the jobs's store, if it was not provided
      if (update.job && !update.storeId) {
        const loadedJobs = queryClient.getQueryData<IJobsCatalogContent>(["jobCatalog"])?.jobs ?? []
        draft.storeId = loadedJobs.find((job) => job.id === update.job)?.store_id
      }

      // When the user searches, we should widen the search target. This is needed when the user is on the homepage, which
      // has `-skilled` and `job_hidden = false`, and they search for nursing jobs, which are counter acted by showHidden.
      if (update.search?.length) {
        draft.showHidden = true
        draft.allJobs = true
      }
    },
    initialState
  )

  // #region URL updating
  // As the user moves around the map, in most cases, we want to update the URL to match a subset of the MapState so it
  // can be loaded later.
  const searchParams = useSearchParams()
  const { mapVisible } = useMap()
  const isMedium = useIsMedium()
  const currentUrl = useCurrentUrl({ includeSearch: false })
  useEffect(() => {
    // On first load, we need to keep the URL parameters the same until the user moves the map, clicks a job, searches,
    // etc. If we do this immediately, Google thinks it is a redirect and will penalize us.
    //
    // We should update the URL if the user has selected a job or store though.
    if (!state.driftedFromDefaultState && !state.storeId && !state.job) {
      return
    }

    const oldSearchParams = new URLSearchParams(searchParams)
    const newSearchParams = getSearchParamsForMapState(
      searchParams,
      // On the mobile map view, users move the map a lot. So much so that we run into a hard limit on how often
      // `history.replaceState` can be called. To avoid this, we only store the lat/lng in the URL when the map is
      // moved. When the map is dismissed and the list view comes back, the URL will update to the new lat/lng. We also
      // want to update the URL if a store is selected so that sharing the job by URL works.
      mapVisible && isMedium && !state.storeId ? { ...state, lat: undefined, lng: undefined } : state
    )

    if (oldSearchParams.toString() !== newSearchParams.toString()) {
      replaceURL(`${currentUrl}?${newSearchParams.toString()}`)
    }
  }, [currentUrl, searchParams, state, mapVisible, isMedium])

  // #region Statsig
  // The user may have been exposed to experiments during server rendering. If they have, we need to log that exposure
  // on the frontend so that external systems are aware of it. Specifically, this allows us to see users in experiments
  // on Hotjar.
  //
  // Statsig logs exposures with the `.get` call.
  const statsig = useStatsigClient()
  useQuery({
    queryKey: ["statsig", "ssrExposedExperiments", ssrExposedExperiments] as const,
    initialData: false,
    queryFn: async ({ queryKey: [, , ssrExposedExperiments] }) => {
      if (!ssrExposedExperiments) {
        return true
      }

      ssrExposedExperiments.forEach((experiment) => {
        if (experiment.type === "layer") {
          const layer = statsig.getLayer(experiment.name)
          experiment.keys.forEach((key) => {
            layer.get(key)
          })
        } else if (experiment.type === "experiment") {
          const exp = statsig.getExperiment(experiment.name)
          experiment.keys.forEach((key) => {
            exp.get(key)
          })
        }
      })

      return true
    },
  })

  /* eslint-disable react-hooks/exhaustive-deps */
  const value = useMemo(() => ({ state, dispatch }), [state])

  return <WorkmapsContext.Provider value={value}>{children}</WorkmapsContext.Provider>
}
