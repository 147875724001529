import Cookies from "js-cookie"
import {
  StatsigProvider as RealStatsigProvider,
  useClientBootstrapInit,
  type StatsigUser,
  type OverrideAdapter,
} from "@statsig/react-bindings"
import { StatsigAutoCapturePlugin } from "@statsig/web-analytics"
import { useMemo } from "react"
import { useUser } from "./UserContext"
import { useIsMedium } from "@/lib/frontend/util"
import { useQuery } from "@tanstack/react-query"
import { useSlug } from "@/lib/frontend/hooks/useSlug"

export type StatsigProviderProps = {
  statsigValues: string
  statsigUser: StatsigUser
}

export const StatsigProvider: React.FC<React.PropsWithChildren<StatsigProviderProps>> = ({
  children,
  statsigValues,
  statsigUser,
}) => {
  const user = useUser()
  const userID = statsigUser.userID ?? user?.id ?? Cookies.get("ajs_user_id")
  const email = statsigUser.email ?? user?.email
  const isMedium = useIsMedium()
  const viewport: undefined | "mobile" | "desktop" =
    isMedium !== undefined ? (isMedium ? "mobile" : "desktop") : undefined
  const slug = useSlug()

  const overrideAdapter = useMemo(() => new CookieBackedOverrideAdapter(), [])
  const statsigClient = useClientBootstrapInit(
    process.env.NEXT_PUBLIC_STATSIG_CLIENT_API_KEY,
    statsigUser,
    statsigValues,
    {
      overrideAdapter,
      plugins: [new StatsigAutoCapturePlugin()],
      environment: {
        tier: process.env.NEXT_PUBLIC_STATSIG_ENVIRONMENT ?? "development",
      },
    }
  )

  useQuery({
    queryKey: ["statsig", "user", { userID, email, statsigUser, viewport, workmapsOriginalSlug: slug?.slug }] as const,
    initialData: null,
    queryFn: async ({ queryKey: [, , { userID, email, statsigUser, viewport, workmapsOriginalSlug }] }) => {
      const updatedUser: StatsigUser = {
        ...statsigUser,
        userID,
        email: email ?? statsigUser.email,
        custom: {
          ...(statsigUser.custom ?? {}),
          viewport,
        },
      }

      if (workmapsOriginalSlug) {
        updatedUser.custom = {
          ...updatedUser.custom,
          workmapsOriginalSlug,
        }
      }

      await statsigClient.updateUserAsync(updatedUser)
      return updatedUser
    },
  })

  return <RealStatsigProvider client={statsigClient}>{children}</RealStatsigProvider>
}

const loggedOverrides = new Set<string>()

// In order to allow easy sharing of in flight features, we have a query parameter convention to control
// experiment/layer/gate values. These changes are persisted in cookies so that the URL can be cleared out
// during development.
class CookieBackedOverrideAdapter implements OverrideAdapter {
  private overrides: Record<string, boolean | Record<string, number | boolean | (string | number | boolean)[]>>

  constructor() {
    this.overrides = JSON.parse(Cookies.get("statsig_overrides") ?? "{}")
  }

  getLayerOverride: OverrideAdapter["getLayerOverride"] = (current, _user, _options) => {
    const updated = { ...current }
    const override = this.overrides?.[current.name]

    if (override && typeof override === "object") {
      updated.__value = { ...current.__value, ...override }
      updated.details = { ...current.details, reason: "LocalOverride" }
      if (!loggedOverrides.has(current.name)) {
        /* eslint-disable-next-line no-console */
        console.log(`Statsig: Overriding config for ${updated.name}`, updated.__value)
        loggedOverrides.add(current.name)
      }
    }

    return updated
  }

  // There is a bug in Statsig's implementation of experiments where `.get` does not respect the overridden value on
  // first fetch. This works fine for layers and gates, but not experiments, for whatever reason. The easiest way to get
  // around this is to do:
  //
  // statsig.getExperiment("experiment_name")
  // const exp = statsig.getExperiment("experiment_name")
  // const enabled = exp.get("is_enabled", false)
  getExperimentOverride: OverrideAdapter["getExperimentOverride"] = (current, _user, _options) => {
    const updated = { ...current }
    const override = this.overrides?.[current.name]

    if (override && typeof override === "object") {
      updated.value = { ...current.value, ...override }
      updated.details = { ...current.details, reason: "LocalOverride" }
      updated.groupName = "LocalOverride"
      if (updated.__evaluation) {
        updated.__evaluation.value = updated.value
      }
      if (!loggedOverrides.has(current.name)) {
        /* eslint-disable-next-line no-console */
        console.log(`Statsig: Overriding config for ${updated.name}`, updated.value)
        loggedOverrides.add(updated.name)
      }
    }

    return updated
  }

  getDynamicConfigOverride: OverrideAdapter["getDynamicConfigOverride"] = (current, _user, _options) => {
    const updated = { ...current }
    const override = this.overrides?.[current.name]

    if (override && typeof override === "object") {
      updated.value = { ...current.value, ...override }
      updated.details = { ...current.details, reason: "LocalOverride" }
      if (!loggedOverrides.has(current.name)) {
        /* eslint-disable-next-line no-console */
        console.log(`Statsig: Overriding config for ${updated.name}`, updated.value)
        loggedOverrides.add(current.name)
      }
    }

    return updated
  }

  getGateOverride: OverrideAdapter["getGateOverride"] = (current, _user, _options) => {
    const updated = { ...current }
    const override = this.overrides[current.name]

    if (typeof override === "boolean") {
      updated.value = override
      if (!loggedOverrides.has(current.name)) {
        /* eslint-disable-next-line no-console */
        console.log(`Statsig: Overriding gate for ${updated.name}`, updated.value)
        loggedOverrides.add(current.name)
      }
    }

    return updated
  }
}
